.ion-header {
  padding: 20px 0px;
  box-shadow: 2px 4px 10px #bbb;
  margin-bottom: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: white;
}
.ion-content {
  margin-top: 80px;
}
.tc_content {
  padding: 10px;
}
.tc_section {
  margin-bottom: 20px;
}
.tc_section_title {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
}
.tc_block {
  margin-bottom: 5px;
}
.tc_subtitle {
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
}
.tc_para {
  font-size: 16px;
}
.tc_bold {
  font-weight: 600;
}
.tc_points > li {
  list-style-type: none;
  font-size: 16px;
  line-height: 1.3;
}
.tc_cp {
  cursor: pointer;
}
::marker {
  display: none;
}
.ion-content {
  height: calc(100vh - 80px);
  padding-left: 10px !important;
}
.ion-title {
  margin-left: 20px;
  font-size: 25px;
}
.mt-3 {
  margin-top: 10px;
}

.abs-right {
  position: absolute;
  right: 10px;
  top: 10px;
}
