.App-link {
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 700;
  outline: none;
  padding: 16px 22px;
  background-color: #fff;
  color: #2caf82;
  cursor: pointer;
}

.App-link[disabled],
.App-link[disabled]:hover {
  background-color: #ccc;
  color: #777;
  cursor: not-allowed;
}

.App-link:hover {
  background-color: #2caf82;
  color: #fff;
}
